<template>
    <a-card>
        <a-page-header
            title="巨量车系映射表"
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="映射结果">
                        <a-select
                            v-model="form.mappingResult"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="1">完成</a-select-option>
                            <a-select-option key="0">待处理</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="阿凡提车系">
                        <a-select
                            mode="multiple"
                            v-model="form.seriesIds"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="巨量车系">
                        <a-input
                            v-model="form.mediaSeriesName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <div class="mt-20">巨量全量车系数据</div>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="(record, index) => index"
            :pagination='false'
            class="mt-20"
        >
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '巨量品牌',
                    dataIndex: 'mediaBrandName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '巨量厂商',
                    dataIndex: 'mediaManufactorName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '巨量车系',
                    dataIndex: 'mediaSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '巨量车系ID',
                    dataIndex: 'mediaSeriesId',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '阿凡提品牌',
                    dataIndex: 'brandName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '阿凡提车系',
                    dataIndex: 'name',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '阿凡提车系ID',
                    dataIndex: 'seriesId',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系在售状态',
                    dataIndex: 'saleStatus',
                    customRender: (text, row) => {
                        let txt = '-'
                        switch(row.saleStatus) {
                            case 1:
                                txt = '在售'
                                break;
                            case 2:
                                txt = '停售'
                                break;
                            case 3:
                                txt = '未售'
                                break;
                            case 4:
                                txt = '即将销售'
                                break;
                        }
                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '映射结果',
                    dataIndex: 'mappingResultName',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            seriesList: [],
        }
    },
    created() {
        this.getPrincipalSeriesList()
        this.getDataList()
    },
    methods: {
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                size: this.pagination.pageSize,
                page: this.pagination.current,
            }
            this.$api.core.materialManage.getAllForCarSeriesList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.link {
    text-decoration: underline;
}
</style>